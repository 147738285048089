import { FetchResponse } from 'types/FetchResponse';
import { BannerItem } from 'types/BannerItem';
import { EventStatus } from 'types/EventStatus';

import { wrapFetch } from 'util/api';

import { ProductData, ProductType } from './product';
import { AnnouncementType } from './events';
import { RoomType } from './course';
import { StoresSearchData } from './store';

interface ActivityInfo {
	date: string;
	timezone: string;
	meeting_place: string;
	destination: string;
	challenge_method: string;
	total_mileage_text: string;
	target_audience: string;
	project: string;
	quota: number;
	left_quota: number;
}

interface ApplyInfo {
	apply_requirement: string;
	apply_date_text: string;
	apply_fee: number;
	apply_fee_text: string;
	checkin_method: string;
	apply_method: string;
	payment: string;
	deadline_text: string;
	deadline_date: string;
}

type ActivityProcessType = {
	id: number;
	time: string;
	title: string;
};

export type DeliveryPriceType = {
	id: number;
	name: string;
	max_people: number;
	min_people: number;
	price: number;
	note: string;
	order: number;
};

export type DeliveryType = 'HOME_DELIVERY' | 'STORE' | 'ON_SITE';

export type DeliveryInfoType = {
	delivery_types: DeliveryType[];
	delivery_prices?: DeliveryPriceType[];
	delivery_price_img?: string;
	delivery_stores?: StoresSearchData[];
};

export type DeliveryRequestParams = {
	delivery_type: DeliveryType;
	delivery_store_id: number;
	delivery_address: string;
};

export type RideLifeType = {
	goal: number;
	goal_type: string;
};

export interface RideData {
	id: number;
	series: string;
	name: string;
	description: string;
	group: string;
	organizer: string;
	distance: string;
	transaction_by_others: boolean;
	note: string;
	active_status: EventStatus;
	show_countdown_day: boolean;
	activity_info: ActivityInfo;
	ride_life: RideLifeType;
	registration_notes: ApplyInfo;
	xplova_url: string;
	extra_field_name_1: string;
	extra_field_name_2: string;
	extra_field_name_3: string;
	activity_process: ActivityProcessType[];
	additional_products: Record<ProductType, ProductData[]>;
	banners: BannerItem[];
	photos: BannerItem[];
	announcements: AnnouncementType[];
	delivery_info: DeliveryInfoType;
	rooms: RoomType[];
}

type FetchRideFunc = (id: string | number) => Promise<FetchResponse<RideData>>;

export const fetchRideFunc: FetchRideFunc = async id => {
	const response = await wrapFetch(`ride/${id}`, {
		method: 'GET',
	});

	return response;
};
