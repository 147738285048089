export type LangCode = 'en' | 'zh-TW' | 'ja';

// // 2022-03-30 首次上 prod ，只有 TW 語系。
export const lanList: LangCode[] = process.env.PRODUCTION ? ['zh-TW'] : ['en', 'zh-TW', 'ja'];

export const LANGUAGE_NAME_MAP: Record<LangCode, string> = {
	'zh-TW': '繁中',
	en: 'EN',
	ja: '日本語',
};

export const LANGUAGE_MOMENT_CODE_MAP: Record<LangCode, string> = {
	en: 'en',
	ja: 'ja',
	'zh-TW': 'zh-tw',
};
