import { FetchResponse } from 'types/FetchResponse';
import { GenderCodes, GenderValuesNotInitial } from 'types/Gender';
import { InvoiceRequestParams } from 'types/Invoice';

import { wrapFetch } from 'util/api';

// import { SelectValue } from 'types/SelectValue';

import {
	// TravelOrderPaymentInfo,
	TravelOrderPaymentInfoData,
	// TravelParticipantInfo,
	TravelParticipantInfoData,
	TravelParticipantPaymentDetail,
} from 'types/TravelOrderParticipantPaymentDetail';

import { TravelReservationReceipt } from 'models/travelOrder';

import { OrderPaymentStatus, OrderStatus } from 'util/order';
// import { Moment } from 'moment';
import { ProductSelect } from './product';
/** -------------------------------------------------------------------------------------------------------------------------*/

interface FetchTravelOrdersIdentityParams {
	gid?: string;
	identityNo?: string;
	birthday?: string;
}

type travelOrderAuthToken = {
	token: string;
};

type FetchTravelAuthByIdentityFunc = (
	params: FetchTravelOrdersIdentityParams,
) => Promise<FetchResponse<travelOrderAuthToken>>;

/**
 * 取得旅遊訂單 authToken
 * @param {FetchTravelOrdersIdentityParams} params
 * @returns {travelOrderAuthToken} token: "fewirkgprejwglerjmger;g;"
 */
export const fetchTravelAuthTokenByIdentityFunc: FetchTravelAuthByIdentityFunc = async data => {
	const response = await wrapFetch('travelOrder/auth', {
		method: 'POST',
		body: JSON.stringify(data),
	});

	return response;
};

/** -------------------------------------------------------------------------------------------------------------------------*/

type FetchTravelParticipantPaymentDetail = (
	selector: string,
) => Promise<FetchResponse<TravelParticipantPaymentDetail>>;

/**
 * 取得旅遊訂單參加者交易明細 - 旅遊訂單第三步驟
 * @param {string} selector
 */
export const fetchTravelParticipantPaymentDetailFunc: FetchTravelParticipantPaymentDetail = async selector => {
	const response = await wrapFetch(
		`travelOrder/${selector}/participants/payment/details`,
		{
			method: 'GET',
		},
		{ selector },
		{ withTravelAuthToken: true },
	);

	return response;
};

/** -------------------------------------------------------------------------------------------------------------------------*/

type UpdateTravelOrderBySelectorFunc = (
	selector: string,
	data: Partial<TravelParticipantRequestParams>[],
) => Promise<FetchResponse<string>>;

/**
 * 更新聯絡人之旅遊參加者訂單資訊 - 待處理
 */
export const updateTravelOrderBySelectorFunc: UpdateTravelOrderBySelectorFunc = async (
	selector,
	data,
) => {
	const response = await wrapFetch(`travelOrder/${selector}`, {
		method: 'PUT',
		body: JSON.stringify({ participants: data }),
	});

	return response;
};

/** -----------------------------------------------------------------------------------------------------------------------*/

type FetchTravelOrderPaymentInfo = (
	selector: string,
	isAdmin?: boolean,
) => Promise<FetchResponse<TravelOrderPaymentInfoData[]>>;

/**
 * 取得旅遊訂單付款資訊 - 旅遊訂單第四步驟
 * @param {string} selector
 */
export const fetchTravelOrderPaymentInfoFunc: FetchTravelOrderPaymentInfo = async (
	selector,
	isAdmin,
) => {
	const response = await wrapFetch(
		`travelOrder/${selector}/transactions`,
		{
			method: 'GET',
		},
		{ selector },
		{ withTravelAuthToken: true, isAdminTravelToken: isAdmin },
	);

	return response;
};
/** -----------------------------------------------------------------------------------------------------------------------*/
export interface TravelParticipant {
	isLoginUser: boolean; // Nullable
	first_name: string;
	last_name: string;
	en_first_name: string; // Nullable
	en_last_name: string; // Nullable
	nationality: string;
	identity_no: string;
	passport: string; // Nullable
	passport_expiration_date: string; // 'YYYY-MM-DD'  // Nullable
	gender: GenderValuesNotInitial;
	birthday: string; // 'YYYY-MM-DD'
	height: number;
	weight: number; // Nullable
	cellphone: string;
	home_phone: string; // Nullable
	email: string;
	country: string; // Nullable
	zip: string; // Nullable
	state: string; // Nullable
	city: string; // Nullable
	address: string; // Nullable
	elder_consent: string; // Nullable
	parent_consent: string; // Nullable
	contact_name: string;
	contact_relationship: string;
	contact_phone: string;
	room_id: number;
	roommate_name: string; // Nullable
	is_own_bicycle: boolean;
	eating_habit: string;
	eating_habit_note: string; // Nullable
	is_medical_record: boolean;
	medical_record_note: string;
	demand: string; // Nullable
	extra_filed_1: string; // Nullable
	extra_filed_2: string; // Nullable
	extra_filed_3: string; // Nullable
}

type TravelParticipantRequestParams = Partial<TravelParticipant> & {
	room_id: number;
	gift_id: number[];
	products: ProductSelect[];
};

export interface OriginalOrder {
	orderSelector: string;
	participants: number[];
}

export type TravelOrderRequestBody = {
	travel_id: number;
	travel_itinerary_id: number;
	totalPrice: number;
	first_name: string;
	last_name: string;
	identity_no: string;
	gender: GenderValuesNotInitial;
	birthday: string; // 'YYYY-MM-DD'
	cellphone: string;
	email: string;
	payment_type: 'CONSOLIDATED' | 'SEPARATE' | null;
	isLoginUser: boolean;
	participants: Partial<TravelParticipantRequestParams>[];
	originalOrder: OriginalOrder | {};
} & Partial<InvoiceRequestParams>;
// & Partial<DeliveryRequestParams>
// Partial<PaymentRequestParams>;

export type TravelOrderResponse = {
	selectors: string[]; // 為訂單唯一亂碼 ex. M4jJ7Ad91wTc
	registeredType: string;
	paymentType: 'CONSOLIDATE' | 'SEPARATE';
};

type SubmitTravelOrderFunc = (
	data: Partial<TravelOrderRequestBody>,
) => Promise<FetchResponse<TravelOrderResponse>>;

/**
 * 建立旅遊訂單
 *
 * @param {Partial<TravelOrderRequestBody>} data
 * @return {TravelOrderResponse} TravelOrderResponse
 */
export const submitTravelOrderFunc: SubmitTravelOrderFunc = async data => {
	const response = await wrapFetch(
		'travelOrder',
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
		{},
		{ withAuth: true },
	);

	return response;
};

/** -------------------------------------------------------------------------------------------------------------------------*/
interface TravelOrderTransactionData {
	paymentCase: string;
	paymentMethod: number;
	transactionIds: number[];
	receipt: {
		type: 'B2C' | 'B2B' | null;
		buyerName: TravelReservationReceipt['buyerName'];
		companyNo: TravelReservationReceipt['companyNo'];
	};
}

export interface TravelOrderTransactionAccountInfoData {
	deadline: string;
	account: string;
}

// interface TravelOrderTransactionResponseData {
// 	type: string;
// 	view?: string;
// 	accountInfo?: TravelOrderTransactionAccountInfoData;
// }

export type TravelOrderTransactionResponse = {
	// data: TravelOrderTransactionResponseData;
	type: string;
	view?: string;
	accountInfo?: TravelOrderTransactionAccountInfoData;
};

type SubmitTravelOrderTransactionFunc = (
	selector: string,
	params: TravelOrderTransactionData,
) => Promise<FetchResponse<TravelOrderTransactionResponse>>;

/**
 * 旅遊訂單 transaction 付款
 * @param {TravelOrderTransactionData} params
 * @param {string} selector
 * @returns {TravelOrderTransactionResponse}
 */
export const submitTravelOrderTransactionFunc: SubmitTravelOrderTransactionFunc = async (
	selector,
	params,
) => {
	const response = await wrapFetch(
		`travelOrder/${selector}/transactions`,
		{
			method: 'POST',
		},
		params,
		{ withTravelAuthToken: true },
	);

	return response;
};

/** -------------------------------------------------------------------------------------------------------------------------*/

type CancelTravelOrderFunc = (selector: string, type: string) => Promise<FetchResponse>;

/**
 * 取消旅遊訂單
 */
export const cancelTravelOrderFunc: CancelTravelOrderFunc = async (
	selector: string,
	type: string,
) => {
	const response = await wrapFetch(
		`travelOrder/${selector}/cancel`,
		{
			method: 'PUT',
		},
		{
			type,
		},
		{ withLocale: false, withLan: true, withTravelAuthToken: true },
	);

	return response;
};

/** -------------------------------------------------------------------------------------------------------------------------*/

export interface TravelRegisterInfo {
	selector: string;
	number: string;
	name: string;
	date: string;
	location: string;
	status: OrderStatus;
	payment_status: OrderPaymentStatus;
	travel_id: string;
}

type FetchTravelRegisterInfoFunc = () => Promise<FetchResponse<TravelRegisterInfo[]>>;

/**
 * 取得旅遊訂單查詢資料 -『我要繳費』第二步驟列表
 */
export const fetchTravelRegisterInfoFunc: FetchTravelRegisterInfoFunc = async () => {
	const response = await wrapFetch(
		`travelOrder`,
		{
			method: 'GET',
		},
		{},
		{ withTravelAuthToken: true },
	);

	return response;
};

/** -------------------------------------------------------------------------------------------------------------------------*/
// 備註欄位待確認中 note
export interface TravelRegisterOrderInfo {
	phone: string;
	birthday: string;
	order_number: string;
	created_at: string;
	name: string;
	email: string;
	status: OrderStatus;
	payment_status: OrderPaymentStatus;
	gender: GenderValuesNotInitial;
	total_amount: number;
	payment_type: 'CONSOLIDATE' | 'SEPARATE';
	isLoginUserPaymentAllowed: boolean;
}

type FetchTravelRegisterOrderInfoFunc = (
	selector: string,
	isAdmin?: boolean,
) => Promise<FetchResponse<TravelRegisterOrderInfo>>;

/**
 * 取得旅遊訂單資訊 -『我要繳費』第三步驟上方訂單資訊
 *
 * 聯絡人資訊 + 該筆訂單資訊
 */
export const fetchTravelRegisterOrderInfoFunc: FetchTravelRegisterOrderInfoFunc = async (
	selector,
	isAdmin,
) => {
	const response = await wrapFetch(
		`travelOrder/${selector}`,
		{
			method: 'GET',
		},
		{},
		{ withTravelAuthToken: true, isAdminTravelToken: isAdmin },
	);

	return response;
};

/** -------------------------------------------------------------------------------------------------------------------------*/
type FetchTravelParticipantInfo = (
	selector: string,
	isAdmin?: boolean,
) => Promise<FetchResponse<TravelParticipantInfoData[]>>;

/**
 * 取得旅遊訂單參加者資訊 -『我要繳費』第三步驟中間參加者資訊
 * @param {string} selector
 */
export const fetchTravelOrderParticipantInfoFunc: FetchTravelParticipantInfo = async (
	selector: string,
	isAdmin,
) => {
	const response = await wrapFetch(
		`travelOrder/${selector}/participants`,
		{
			method: 'GET',
		},
		{ selector },
		{ withTravelAuthToken: true, isAdminTravelToken: isAdmin },
	);

	return response;
};
