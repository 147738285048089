/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable no-mixed-operators */
import moment, { Moment } from 'moment';
import { CountryCode } from 'libphonenumber-js';

import { DynamicState } from 'types/DynamicState';
import { PhoneValue } from 'types/PhoneValue';
import { SelectValue } from 'types/SelectValue';
import { LocationData } from 'types/LocationData';

import { globalLocal } from 'models/locale';

import { appPathKey } from './routingConfig';
import { localeUrlRegex, stringWithoutSpaces, twPhoneNumberRegex } from './regex';

export function getParentNode<T extends HTMLElement>(node: HTMLElement): T {
	return node.parentNode as T;
}

export const focusInChildren: (
	relatedTarget: EventTarget,
	currentTarget: EventTarget,
) => {} | boolean = (relatedTarget: EventTarget, currentTarget: EventTarget) => {
	if (relatedTarget === null) {
		return false;
	}

	if (relatedTarget === currentTarget) {
		return true;
	}

	const targetParent = getParentNode(relatedTarget as HTMLElement);

	return focusInChildren(targetParent, currentTarget);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isExist = (value: any) =>
	value !== null && value !== '' && typeof value !== 'undefined' && value !== 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => !isExist(value);

/**
 * 如果值是 null， 返回 undefined
 */
export const nullToUndefined = <T>(value: T): NonNullable<T> | undefined =>
	value === null ? undefined : (value as NonNullable<T>);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectHasValue = (obj: any) => {
	const values = Object.values(obj);
	for (let i = 0; i < values.length; i += 1) {
		if (isExist(values[i])) return true;
	}
	return false;
};

export const range = (start: number, length: number) =>
	Array.from(new Array(Math.abs(length)), (_, i) => start + i * (length >= 0 ? 1 : -1));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const chunkArray = <T>(array: T[], chunkSize: number) => {
	const results = [];
	const newArray = [...array];

	while (newArray.length) {
		results.push(newArray.splice(0, chunkSize));
	}

	return results;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const suppleArray = (array: any[], toLength: number) => {
	const newArray = [...array];
	const diffLength = toLength - newArray.length;
	const restSuppleArray = Array.from({ length: diffLength }, () => ({}));
	return [...newArray, ...restSuppleArray];
};

export const thousandComma = (oldNum: number) => {
	let newNum: string = oldNum.toString();
	const pattern = /(-?\d+)(\d{3})/;

	while (pattern.test(newNum)) {
		newNum = newNum.replace(pattern, '$1,$2');
	}
	return newNum;
};

export const removeCommas = (inputString: string) => {
	// Use the replace method with a regular expression to remove commas
	return inputString.toString().replace(/,/g, '');
};

export const sleep = (time: number) =>
	new Promise<void>(resolve => setTimeout(() => resolve(), time));

export const pad = (number = 0, width = 1, z = '0') => {
	const numberLength = `${number}`.length;
	return numberLength >= width ? `${number}` : new Array(width - numberLength + 1).join(z) + number;
};

export const getElementPosition = (element: HTMLElement) => {
	let target: HTMLElement | null = element;
	const pos = { x: target.offsetLeft, y: target.offsetTop };
	target = target.offsetParent as HTMLElement;
	while (target) {
		pos.x += target.offsetLeft;
		pos.y += target.offsetTop;
		target = target.offsetParent as HTMLElement;
	}
	return pos;
};

export const intIdNum2CountryCode: { [key: string]: CountryCode } = {
	'+886': 'TW',
	'+86': 'CN',
	'+1': 'US',
};

export const replaceChar = (myString: string, index: number, replacement: string) =>
	myString.substring(0, index) + replacement + myString.substring(index + replacement.length);

export const mobileAndTabletCheck = () => {
	let check = false;
	(a => {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
				a,
			) ||
			// eslint-disable-next-line no-useless-escape
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4),
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
};

export const groupBy = <T>(key: keyof T) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (data: any, item: T) => {
		// eslint-disable-next-line no-param-reassign
		(data[item[key]] = data[item[key]] || []).push(item);
		return data;
	};
};

export const assignBy = <T>(key: keyof T) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (data: any, item: T) => {
		// eslint-disable-next-line no-param-reassign
		data[item[key]] = item;
		return data;
	};
};

/**
 * 判斷目前 URL 中的地區（locale） route 是 Global 還是其他地區，並回傳對應的物件資料。
 *
 * @param data locale model 回傳的 Locale Map 物件（locale.locales.data.byCode）
 * @returns 目前 Locale 的物件資料
 */
export const localeParser = (data: { [code: string]: LocationData }) => {
	const isGlobal = !localeUrlRegex.test(window.location.pathname);

	/*
	2022-03-30 首次上正式機（prod），暫時不啟用全球首頁。
	原本預設的 locale 是 global，暫時設定以 'TW' 為預設 locale，藉此隱藏全球首頁。
	在非正式機環境，即 stage / demo / local ，時才判斷 isGlobal 並進到全球首頁。
	*/
	if (isGlobal && !process.env.PRODUCTION) {
		return { isGlobal, valid: true, locale: globalLocal };
	}

	/* 如果不是全球頁面，截取目前所在的 locale 字串 */
	const [, urlLocaleCode] = window.location.pathname.split('/');
	const loc = data[urlLocaleCode];

	return {
		isGlobal: process.env.PRODUCTION ? false : isGlobal,
		valid: isExist(loc),
		locale: loc || globalLocal,
	};
};

export const reduceArr = (
	arr: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
	key: string,
) =>
	arr.reduce((acc, val) => {
		const found = acc.find((a: { [x: string]: any }) => a[key] === val[key]); // eslint-disable-line @typescript-eslint/no-explicit-any
		if (!found) {
			acc.push({ productId: val.productId, [key]: val[key], data: [val], stockSum: val.stock });
		} else {
			found.data.push(val);
			found.stockSum += val.stock;
		}
		return acc;
	}, []);

export const getDifference = (start: Moment, end: Moment) => {
	// const start = moment(startDate);
	// const end = moment(endDate);
	const diff = {
		days: end.diff(start, 'days'),
		hours: end.diff(start, 'hours') % 24,
		mins: end.diff(start, 'minutes') % 60,
	};
	return diff;
};

export const isNumber = (value: unknown) => !Number.isNaN(Number(value));

const isPathsHasOneSupport = (allSupportPath: appPathKey[], checkingPaths: appPathKey[]) =>
	checkingPaths.some(path => allSupportPath.includes(path));

export const pathSupportChecker = <Keys extends string>(
	allSupportPath: appPathKey[],
	pathSet: Record<Keys, appPathKey[]>,
): Record<Keys, boolean> =>
	Object.entries(pathSet).reduce((a, [key, paths]) => {
		const obj = { ...a };
		obj[key as Keys] = isPathsHasOneSupport(allSupportPath, paths as appPathKey[]);
		return obj;
	}, {} as Record<Keys, boolean>);

export const visaIdFormatter = (id: string) => {
	const formatId = id
		.replace(/[^\d]/g, '')
		.replace(/(\s)/g, '')
		.replace(/(\d{4})/g, '$1 ')
		.replace(/\s*$/, '');
	return formatId;
};

export const expiredDateFormatter = (date: string) => {
	const formatDate = date.replace(/[^\d/]/g, '');
	if (formatDate.length === 2) {
		return `${formatDate}/`;
	}
	if (formatDate.length === 3 && formatDate[2] === '/') {
		return formatDate.split('/')[0];
	}
	if (formatDate.length === 3 && formatDate[2] !== '/') {
		return `${formatDate.slice(0, 2)}/${formatDate[2]}`;
	}
	return formatDate.replace(/(\s)/g, '');
};

export const handleInvalidInput = (
	key: string,
	changeFrom: (params: any) => void,
	errorMsg: string,
) => {
	changeFrom({
		key,
		data: {
			valid: false,
			error: errorMsg,
		},
	});
};

export const initDynamicState = <D>(data: D, loading = false, error = ''): DynamicState<D> => ({
	loading,
	error,
	data,
});

export const initSelectValue = <V extends unknown = null, D extends unknown = object>(
	label?: string,
	value?: V,
	data?: D,
): SelectValue<V | null> => ({
	label: label || '',
	value: value || null,
	...(typeof data === 'object' && Object.keys(data as object).length > 0 && { data }),
});

export const initPhoneValue = (): PhoneValue => ({ intIdNum: '', phoneNum: '' });

/**
 * 將信用卡卡號 string 去識別化，index < 15 的數值都回傳 '*'。
 * e.g. **** **** **** 5566
 *
 * @param {string} cardId 去識別化後的信用卡號 string
 */
export const creditCardDeIdentificator = (cardId: string) =>
	cardId.replace(/\w/g, (match, offset) => {
		if (offset === 15 || offset === 16 || offset === 17 || offset === 18) {
			return match;
		}
		return '*';
	});

export const createRandomNumberPostfix = (v: string | number) => {
	return `${v}_${`${Math.random()}`.split('.')[1]}`;
};

/**
 * 將後端回傳的 Error Message 轉換成前端統一的 ABC_DEF_GHI 作為 i18n 翻譯檔的 KEY。
 *
 * @param msg 後端回傳的 Error Message
 * @param splitter
 * @returns 翻譯檔錯誤訊息的 KEY
 */
export const createErrorMsgKey = (msg: string, splitter = ' ') =>
	msg
		.split(splitter)
		.join('_')
		.toLocaleUpperCase();

/**
 * 根據電話國碼修飾電話號碼的呈現方式，並且預設刪除所有空白字元
 *
 * @param phoneNum
 * @param intIdNum
 * @param modifier
 * @returns
 */
export const modifyPhoneByIntId = (phoneNum: string, intIdNum: string, modifier = '') => {
	switch (intIdNum) {
		case 'TW(+886)':
			return phoneNum.replace(twPhoneNumberRegex, modifier);
		default:
			return phoneNum.replace(stringWithoutSpaces, modifier);
	}
};

/**
 * 根據國家縮寫（如 'TW'）尋找 countryCodesByCodes 內部的 'TW(+886)'
 * @param obj
 * @param country
 * @returns ex.'TW(+886)'
 */
export function findIntNumByCountry(obj: object, country: string) {
	const foundKey = Object.keys(obj).find(key => key.includes(country));
	return foundKey || '';
}

/**
 * 計算活動課程的報名截止日
 * ref：https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/issues/760#note_974285
 * 可以使用 subtract 等，moment function 去修改日期
 * @param deadline
 * @returns
 */
export const getGiantEventDeadline = (deadline: string) => moment(deadline).format('YYYY/MM/DD');

/**
 * 判斷輸入的日期格式是單一日期還是日期範圍
 *
 * @export
 * @param {*} input
 * @return {*} boolean
 */
export function isDateRange(input: string): any {
	// Define the regex for a single date and a date range
	const singleDateRegex = /^\d{4}\/\d{2}\/\d{2}$/; // e.g., "2024/11/30"
	const rangeDateRegex = /^\d{4}\/\d{2}\/\d{2}\s~\s\d{4}\/\d{2}\/\d{2}$/; // e.g., "2024/12/01 ~ 2024/12/07"

	if (rangeDateRegex.test(input)) {
		return true; // It's a range
	}
	if (singleDateRegex.test(input)) {
		return false; // It's a single date
	}
	throw new Error('Invalid date format');
}
