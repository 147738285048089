/* eslint-disable indent */
import { handleActions, createAction, Action } from 'redux-actions';
import moment from 'moment';
import { Dispatch } from 'redux';

import { FetchedData } from 'types/FetchedData';
import { EventStatus } from 'types/EventStatus';

import { useRedux } from 'util/hook/redux';
import { getDifference } from 'util/helper';
import { notLessThan } from 'util/functions/notLessThan';

import { updateProductData } from 'models/product';
import { normalizedProduct } from 'models/product/utils';

import { fetchRideFunc } from 'api/ride';
import { fetchScore } from 'api/score';
import { AnnouncementType } from 'api/events';

import { SlideProperty } from 'components/molecules/SliderBanner';
import { PaginationData } from 'types/PaginationData';

import {
	AlbumDataType,
	FormattedRideData,
	GiveawayCardDataProperty,
	GiveawayDataProperty,
	ServiceADPType,
	TimeLeftType,
	TimelineDataItem,
	ScoreType,
} from './type';
import { GetState, State as GlobalState } from '../reducers';

export interface State {
	detail: FetchedData<{
		mainInfo: {
			title: string;
			description: string;
			activeStatus: EventStatus;
		};
		activityInfo: {
			[key: string]: string | number;
		};
		ride_life: {
			goal: number;
			goal_type: string;
		} | null;
		applyInfo: {
			applyCondition: string;
			applyDate: string;
			applyFee: string;
			applyFeeText: string;
			checkinMethod: string;
			applyMethod: string;
			payment: string;
			deadlineText: string;
		};
		scheduleData: TimelineDataItem[];
		giveawayData: GiveawayDataProperty[];
		additionalPurchase: {
			productADPData: GiveawayCardDataProperty[];
			serviceADPData: ServiceADPType[];
		};
		albumData: AlbumDataType[];
		banners: SlideProperty[];
		distanceAndTimeLimitData: string;
		activityGroup: string;
		activityOrganizer: string;
		note: string;
		xplovaUrl: string;
		announcements: AnnouncementType[];
		announcementIndex: number;
		showCountdownDay: boolean;
		timeLeft: TimeLeftType;
	}>;
	score: FetchedData<PaginationData<ScoreType[]>>;
	reservationRideInfo: FetchedData<Partial<FormattedRideData>>;
}

const initialState: State = {
	detail: {
		data: {
			mainInfo: {
				title: '',
				description: '',
				activeStatus: EventStatus.DEADLINE,
			},
			activityInfo: {
				activityDate: '',
				collectPlace: '',
				activityTermination: '',
				challengMode: '',
				activityDistance: '',
				targetAudience: '',
				activityItems: '',
				left_quota: 0,
				quota: 0,
			},
			ride_life: {
				goal: 0,
				goal_type: '',
			},
			applyInfo: {
				applyCondition: '',
				applyDate: '',
				applyFee: '',
				applyFeeText: '',
				checkinMethod: '',
				applyMethod: '',
				payment: '',
				deadlineText: '',
			},
			scheduleData: [],
			giveawayData: [],
			additionalPurchase: {
				productADPData: [],
				serviceADPData: [],
			},
			albumData: [],
			banners: [],
			distanceAndTimeLimitData: '',
			activityGroup: '',
			activityOrganizer: '',
			note: '',
			xplovaUrl: '',
			announcements: [],
			announcementIndex: -1,
			showCountdownDay: false,
			timeLeft: {
				days: 0,
				hours: 0,
				mins: 0,
			},
		},
		loading: false,
		error: '',
	},
	score: {
		data: {
			current_page: 1,
			first_page_url: '',
			last_page: 1,
			last_page_url: '',
			next_page_url: '',
			per_page: 1,
			prev_page_url: '',
			total: 0,
			data: [],
			count: 0,
		},
		loading: false,
		error: '',
	},
	reservationRideInfo: {
		loading: false,
		error: '',
		data: {},
	},
};

const changeAnnouncementIndex = createAction('CHANGE_ANNOUNCEMENT_INDEX');

export const getScore = createAction(
	'GET_SCORE',
	(eventType: string, id: number, keyword = '') => async (_, getState: GetState) => {
		try {
			const {
				routing: {
					queries: { page },
				},
			} = getState();

			const fetchScoreParams = {
				key_word: keyword,
				page,
			};

			const {
				status,
				message,
				data: { data, ...params },
			} = await fetchScore(eventType, id, fetchScoreParams);

			if (status !== 200) {
				throw new Error(message);
			}

			let transformScoreData;
			if (Array.isArray(data)) {
				transformScoreData = data.map(item => ({
					id: item.id,
					name: item.name,
					number: item.race_number,
					rank: item.race_number,
					project: item.event_name,
					time: item.total_time,
					group: item.classification,
				}));
			}

			return { data: transformScoreData, ...params };
		} catch (err) {
			console.log(err);
		}
	},
);

const getRideDetail = createAction('GET_RIDE_DETAIL', async (id: string) => {
	try {
		const { status, message, data } = await fetchRideFunc(id);
		const timeObj = getDifference(moment(), moment(data.registration_notes.deadline_date));
		// 小於零的數字取 0 （倒數計時）
		const timeLeftFormatter = Object.fromEntries(
			Object.entries(timeObj).map(item => [item[0], notLessThan(item[1], 0)]),
		);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const rideLifeData = data.ride_life !== null ? data.ride_life : null;

		return {
			data: {
				mainInfo: {
					title: data.name,
					description: data.description,
					activeStatus: data.active_status,
				},
				activityInfo: {
					activityDate: data.activity_info.date,
					collectPlace: data.activity_info.meeting_place,
					activityTermination: data.activity_info.destination,
					challengMode: data.activity_info.challenge_method,
					activityDistance: data.activity_info.total_mileage_text,
					targetAudience: data.activity_info.target_audience,
					activityItems: data.activity_info.project,
					quota: data.activity_info.quota,
				},
				applyInfo: {
					applyCondition: data.registration_notes.apply_requirement,
					applyDate: data.registration_notes.apply_date_text,
					applyFee: data.registration_notes.apply_fee,
					applyFeeText: data.registration_notes.apply_fee_text,
					deadlineText: data.registration_notes.deadline_text,
					applyMethod: data.registration_notes.apply_method,
					checkinMethod: data.registration_notes.checkin_method,
					payment: data.registration_notes.payment,
				},
				ride_life: {
					...rideLifeData,
				},
				scheduleData: [...data.activity_process],
				giveawayData: data.additional_products.gifts.map(({ id: gifeId, name, img }) => ({
					id: gifeId,
					itemName: name,
					imageUrl: img,
				})),
				additionalPurchase: {
					productADPData: data.additional_products.products.map(({ id: productId, name, img }) => ({
						id: productId,
						itemName: name,
						imageUrl: img,
					})),
					serviceADPData: data.additional_products.services.map(
						({ id: serviceId, name, description, price, size_name }) => ({
							id: serviceId,
							item: name,
							time: description,
							cost: price,
							content: size_name,
						}),
					),
				},
				albumData: data.photos.map(({ type, url }, index) => ({
					id: index,
					type,
					imageUrl: url,
				})),
				banners: [...data.banners],
				distanceAndTimeLimitData: data.distance,
				activityGroup: data.group,
				activityOrganizer: data.organizer,
				note: data.note,
				xplovaUrl: data.xplova_url,
				showCountdownDay: data.show_countdown_day,
				timeLeft: timeLeftFormatter,
				announcements: [...data.announcements],
				announcementIndex: data.announcements.length > 0 ? 0 : -1,
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;

			if (message !== 'not found') {
				return {
					data: {},
				};
			}
		}
		throw error;
	}
});

export type GetRideReservationInfoPayload = Partial<FetchedData<FormattedRideData | null>>;

/**
 * getRideReservationInfo 參數 country_id 是為了讓報名查詢頁面 （global route）帶入活動的 country id 設定的
 * 若是在國家底下的頁面無需設定 country_id，會自動帶入。
 */
export const getRideReservationInfo = createAction<
	(_: Dispatch, __: GetState) => Promise<GetRideReservationInfoPayload>,
	string | number
>('GET_RIDE_RESERVATION_INFO', id => async dispatch => {
	try {
		const { status, message, data } = await fetchRideFunc(id);

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		const { products, services, gifts, apply_type } = data.additional_products;
		const formattedProducts = normalizedProduct(products);
		const formattedServices = normalizedProduct(services);
		const formattedGifts = normalizedProduct(gifts);
		const formattedApplyType = normalizedProduct(apply_type);
		dispatch(
			updateProductData({
				products: {
					...formattedProducts.products,
					...formattedServices.products,
					...formattedGifts.products,
					...formattedApplyType.products,
				},
				stocks: {
					...formattedProducts.stocks,
					...formattedServices.stocks,
					...formattedGifts.stocks,
					...formattedApplyType.stocks,
				},
				sizes: {
					...formattedProducts.sizes,
					...formattedServices.sizes,
					...formattedGifts.sizes,
					...formattedApplyType.sizes,
				},
				colors: {
					...formattedProducts.colors,
					...formattedServices.colors,
					...formattedGifts.colors,
					...formattedApplyType.colors,
				},
			}),
		);

		return {
			data: {
				...data,
				additionalsByProductId: {
					products: formattedProducts.ids,
					services: formattedServices.ids,
					gifts: formattedGifts.ids,
					applyTypes: formattedApplyType.ids,
				},
			},
		};
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;

			if (message !== 'not found') {
				return { error: message, data: null };
			}
		}
		return {
			error: '',
		};
	}
});

export const reducer = {
	ride: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_RIDE_DETAIL_PENDING: state => ({
				...state,
				detail: {
					...state.detail,
					loading: true,
					error: '',
				},
			}),

			GET_RIDE_DETAIL_FULFILLED: (state, action) => ({
				...state,
				detail: {
					...state.detail,
					data: action.payload.data,
					loading: false,
					error: '',
				},
			}),

			GET_RIDE_DETAIL_REJECTED: (state, action) => ({
				...state,
				detail: {
					...state.detail,
					loading: false,
					error: action.payload.message,
				},
			}),

			CHANGE_ANNOUNCEMENT_INDEX: state => ({
				...state,
				detail: {
					...state.detail,
					data: {
						...state.detail.data,
						announcementIndex: state.detail.data.announcementIndex + 1,
					},
				},
			}),

			GET_RIDE_RESERVATION_INFO_PENDING: state => ({
				...state,
				reservationRideInfo: { ...initialState.reservationRideInfo, loading: true },
			}),

			GET_RIDE_RESERVATION_INFO_FULFILLED: (
				state,
				action: Action<GetRideReservationInfoPayload>,
			) => ({
				...state,
				reservationRideInfo: {
					...state.reservationRideInfo,
					loading: false,
					...(action.payload?.data && {
						data: action.payload.data,
					}),
					...(action.payload?.error && {
						error: action.payload.error,
					}),
				},
			}),
			GET_SCORE_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_SCORE_FULFILLED: (state, action) => ({
				...state,
				score: {
					loading: false,
					data: action.payload,
					error: action.payload.message,
				},
			}),
			GET_SCORE_REJECTED: state => ({
				...state,
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useRideDetail ++
++----------------------------------------------------------------------*/
const selectActivityDetail = (state: GlobalState) => ({
	mainInfo: state.ride.detail.data.mainInfo,
	activityInfo: state.ride.detail.data.activityInfo,
	ride_life: state.ride.detail.data.ride_life,
	applyInfo: state.ride.detail.data.applyInfo,
	scheduleData: state.ride.detail.data.scheduleData,
	giveawayData: state.ride.detail.data.giveawayData,
	albumData: state.ride.detail.data.albumData,
	banners: state.ride.detail.data.banners,
	distanceAndTimeLimitData: state.ride.detail.data.distanceAndTimeLimitData,
	activityGroup: state.ride.detail.data.activityGroup,
	activityOrganizer: state.ride.detail.data.activityOrganizer,
	note: state.ride.detail.data.note,
	xplovaUrl: state.ride.detail.data.xplovaUrl,
	announcements: state.ride.detail.data.announcements,
	announcementIndex: state.ride.detail.data.announcementIndex,
	showCountdownDay: state.ride.detail.data.showCountdownDay,
	additionalPurchase: state.ride.detail.data.additionalPurchase,
	timeLeft: state.ride.detail.data.timeLeft,
	error: state.ride.detail.error,
});

const selectScore = (state: GlobalState) => ({
	score: state.ride.score,
});

export const useRideScore = () => useRedux(selectScore, { getScore });

export const useRideDetail = () =>
	useRedux(selectActivityDetail, { getRideDetail, changeAnnouncementIndex });

/* +----------------------------------------------------------------------
	++ useAdditionalPurchase ++
++----------------------------------------------------------------------*/
const selectAdditionalPurchaseDetail = (state: GlobalState) => ({
	additionalPurchase: state.ride.detail.data.additionalPurchase,
});

export const useAdditionalPurchase = () => useRedux(selectAdditionalPurchaseDetail, {});

/* +----------------------------------------------------------------------
	++ useRideReservationInfo ++
++----------------------------------------------------------------------*/
const selectRideReservationInfo = (state: GlobalState) => state.ride.reservationRideInfo;

const rideReservationInfoActionsMap = {
	getRideReservationInfo,
};

export const useRideReservationInfo = () =>
	useRedux<ReturnType<typeof selectRideReservationInfo>, typeof rideReservationInfoActionsMap>(
		selectRideReservationInfo,
		rideReservationInfoActionsMap,
	);
