import { GiantEventData } from 'types/GiantEvents';
import { GenderCodes } from 'types/Gender';
import { FetchResponse } from 'types/FetchResponse';
import { EventStatus } from 'types/EventStatus';
import { InvoiceRequestParams } from 'types/Invoice';
import { RoomIconType } from 'types/RoomInfo';

import { wrapFetch } from 'util/api';
import { OrderInfo, OrderProduct } from 'util/order';
import { PaymentRequestParams } from 'util/payment';

import { StoresSearchData } from './store';
import { DeliveryPriceType, DeliveryRequestParams, DeliveryType } from './ride';
import { EventLocation } from './events';
import { ProductSelect } from './product';

export interface RideParticipant {
	id: number;
	first_name: string;
	last_name: string;
	gender: GenderCodes;
	identity_no: string;
	height: number;
	phone: string;
	phone_country_code: { name: string; code: string };
	email: string;
	nationality: string;
	birthday: string;
	eating_habit: null | string;
	eating_habit_note: null | string;
	medical_record: boolean;
	medical_record_note: string;
	contact_name: string;
	contact_relationship: string;
	contact_phone: string;
	contact_phone_country_code: { name: string; code: string };
	room_name: string;
	room_icon: RoomIconType;
	roommate_name: string;
	parent_consent: string | null;
	elderlies_consent: string | null;
	extra_field_1: string;
	extra_field_2: string;
	extra_field_3: string;
	products: OrderProduct[];
	services: OrderProduct[];
	gift: OrderProduct[];
	apply_type: OrderProduct[];
}

type RideParticipantRequestParams = Pick<
	RideParticipant,
	| 'first_name'
	| 'last_name'
	| 'nationality'
	| 'identity_no'
	| 'gender'
	| 'birthday'
	| 'height'
	| 'phone'
	| 'email'
	| 'contact_name'
	| 'contact_relationship'
	| 'contact_phone'
	| 'extra_field_1'
	| 'extra_field_2'
	| 'extra_field_3'
	| 'roommate_name'
	| 'eating_habit'
	| 'eating_habit_note'
	| 'medical_record'
	| 'medical_record_note'
	| 'parent_consent'
> & {
	room_id: number;
	apply_type_id: number;
	gift_id: number[];
	products: ProductSelect[];
};

export interface OrderDeliveryInfo {
	delivery_type: DeliveryType;
	delivery_price: DeliveryPriceType['price'];
	delivery_store_id: StoresSearchData['id'];
	delivery_store: StoresSearchData;
}

export interface RideOrder extends OrderInfo {
	name: string;
	ride_type: GiantEventData.eventsFeaturedRides | GiantEventData.eventsStoreRides;
	active_status: EventStatus;
	activity_name: string;
	team_name: string;
	date: null | string;
	apply_fee: number;
	phone: string;
	cellphone: string;
	country_id: number;
	email: string;
	identity_no: string;
	extra_field_name_1: string;
	extra_field_name_2: string;
	extra_field_name_3: string;
	delivery_info: OrderDeliveryInfo;
	participants: RideParticipant[];
	ride_id: number;
}

type FetchRideOrderBySelectorFunc = (selector: string) => Promise<FetchResponse<RideOrder>>;

/**
 * 取得騎乘活動訂單詳細資訊
 */
export const fetchRideOrderBySelectorFunc: FetchRideOrderBySelectorFunc = async selector => {
	const response = await wrapFetch(`rideOrder/${selector}`, {
		method: 'GET',
	});

	return response;
};

export type RideOrderPreview = Pick<
	RideOrder,
	'id' | 'number' | 'selector' | 'status' | 'payment_status' | 'activity_name' | 'date' | 'timezone'
> & {
	model: 'RideOrder';
	type: GiantEventData.eventsFeaturedRides | GiantEventData.eventsStoreRides;
	is_history: boolean;
	locations: EventLocation[];
};

interface FetchRideOrdersIdentityParams {
	activity_id: number;
	identity_no: string;
	cellphone: string;
}

type FetchRideOrdersByIdentityFunc = (
	params: FetchRideOrdersIdentityParams,
) => Promise<FetchResponse<RideOrderPreview[]>>;

/**
 * 取得聯絡人之騎乘活動訂單編號
 */
export const fetchRideOrdersByIdentityFunc: FetchRideOrdersByIdentityFunc = async params => {
	const response = await wrapFetch(
		'rideOrder',
		{
			method: 'GET',
		},
		params,
	);

	return response;
};

type UpdateRideOrderBySelectorFunc = (
	selector: string,
	data: Partial<RideParticipant>[],
) => Promise<FetchResponse<string>>;

/**
 * 更新聯絡人之騎乘活動參加者訂單資訊
 */
export const updateRideOrderBySelectorFunc: UpdateRideOrderBySelectorFunc = async (
	selector,
	data,
) => {
	const response = await wrapFetch(`rideOrder/${selector}`, {
		method: 'PUT',
		body: JSON.stringify({ participants: data }),
	});

	return response;
};

export type ValidateRideParticipantRequestParams = {
	ride_id?: number;
	date?: string;
	course_id?: number;
	participants: {
		name: string;
		identity_no: string;
		is_primary?: boolean;
	}[];
};

/**
 * 驗證 課程、騎乘活動（包含活動課程、主題活動與門市約騎）參加者表單 - 身分證是否重複報名、活動是否存在、是否超過報名名額
 */
export const validationRideParticipantFunc = async (data: ValidateRideParticipantRequestParams) => {
	const response = await wrapFetch(
		'rideOrder/participants/verify',
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
		{},
		{ notThrowError: true },
	);

	return response;
};

export type RideOrderRequestBody = {
	ride_id: number;
	total_price: number;
	newebpay_token_id: number;
	team_name: string;
	name: string;
	cellphone: string;
	phone: string;
	email: string;
	identity_no: string;
	participants: RideParticipantRequestParams[];
} & Partial<DeliveryRequestParams> &
	Partial<InvoiceRequestParams> &
	Partial<PaymentRequestParams>;

export type RideOrderResponse = {
	selector?: string;
	view?: string;
	product_stock_errors?: {
		[id: number]: string;
	};
};

type SubmitRideOrderFunc = (
	data: Partial<RideOrderRequestBody>,
) => Promise<FetchResponse<RideOrderResponse>>;

/**
 * 建立騎乘活動訂單
 */
export const submitRideOrderFunc: SubmitRideOrderFunc = async data => {
	const response = await wrapFetch(
		'rideOrder',
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
		{},
		{ withAuth: true },
	);

	return response;
};

type RideOrderTransactionData = Partial<InvoiceRequestParams> & Partial<PaymentRequestParams>;

export type RideOrderTransactionResponse = {
	view?: string;
};

type SubmitRideOrderTransactionFunc = (
	selector: string,
	id: number,
	data: RideOrderTransactionData,
) => Promise<FetchResponse<RideOrderTransactionResponse>>;

/**
 * 騎乘活動訂單 transaction 付款
 */
export const submitRideOrderTransactionFunc: SubmitRideOrderTransactionFunc = async (
	selector,
	id,
	data,
) => {
	const response = await wrapFetch(`rideOrder/${selector}/transaction/${id}`, {
		method: 'POST',
		body: JSON.stringify(data),
	});

	return response;
};

type CancelRideOrderFunc = (selector: string) => Promise<FetchResponse>;

export const cancelRideOrderFunc: CancelRideOrderFunc = async selector => {
	const response = await wrapFetch(`rideOrder/${selector}/cancel`, {
		method: 'PUT',
	});

	return response;
};
