import { TravelParticipantForms } from './type';

export const formTemplates: TravelParticipantForms = {
	travel: {
		// 基本資料
		sameAs: { value: null, valid: true, error: '', required: false }, // null | 'contactInfo' | 'memberInfo'
		asMember: { value: { label: '', value: null }, valid: true, error: '', required: true },
		lastName: { value: '', valid: true, error: '', required: true },
		firstName: { value: '', valid: true, error: '', required: true },
		lastNameEn: { value: '', valid: true, error: '', required: true },
		firstNameEn: { value: '', valid: true, error: '', required: true },
		nationality: { value: '', valid: true, error: '', required: true },
		citizenId: { value: '', valid: true, error: '', required: true },
		passport: { value: '', valid: true, error: '', required: true },
		passportExpirationDate: { value: null, valid: true, error: '', required: true },
		sex: { value: { label: '', value: null }, valid: true, error: '', required: true },
		birthday: { value: null, valid: true, error: '', required: true },
		height: { value: '', valid: true, error: '', required: true },
		weight: { value: '', valid: true, error: '', required: true },
		citizenship: { value: { label: '', value: null }, valid: true, error: '', required: true },
		isLoginUser: { value: false, valid: true, error: '', required: true },
		paymentType: { value: { label: '', value: null }, valid: true, error: '', required: true },

		// 聯絡資訊
		phone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		homePhone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: true },
		country: { value: '', valid: true, error: '', required: true },
		zip: { value: '', valid: true, error: '', required: true },
		state: { value: '', valid: true, error: '', required: true },
		city: { value: '', valid: true, error: '', required: true },
		address: { value: '', valid: true, error: '', required: true },
		emergencyName: { value: '', valid: true, error: '', required: true },
		emergencyRelation: {
			value: { label: '', value: null },
			valid: true,
			error: '',
			required: true,
		},
		emergencyPhone: {
			value: { intIdNum: '', phoneNum: '' },
			valid: true,
			error: '',
			required: true,
		},
		parentConsent: { value: null, valid: true, error: '', required: true },
		elderliesConsent: { value: null, valid: true, error: '', required: true },
		stayRoom: { value: null, valid: true, error: '', required: true },
		roommate: { value: '', valid: true, error: '', required: false },
		roomPrice: { value: '', valid: true, error: '', required: false },
		isOwnBicycle: { value: { label: '', value: null }, valid: true, error: '', required: true },
		isPrimary: { value: true, valid: true, error: '', required: false },
		// 加價購租車車款
		eatingHabit: { value: { label: '', value: null }, valid: true, error: '', required: true },
		eatingHabitNote: { value: '', valid: true, error: '', required: false },
		isMedicalRecord: {
			value: { label: '', value: null },
			valid: true,
			error: '',
			required: true,
		},
		medicalRecordNote: { value: '', valid: true, error: '', required: false },
		// 同參加者 \ 公司行號
		// receiptType: { value: { label: '', value: null }, valid: true, error: '', required: true },
		// 抬頭
		// companyTitle: { value: '', valid: true, error: '', required: true },
		// 統編
		// companyNo: { value: '', valid: true, error: '', required: true },

		demand: { value: '', valid: true, error: '', required: false },
		servicePurchase: { value: {}, valid: true, error: '', required: false },
		productPurchase: { value: {}, valid: true, error: '', required: false },

		custom1: { value: '', valid: true, error: '', required: false },
		custom2: { value: '', valid: true, error: '', required: false },
		custom3: { value: '', valid: true, error: '', required: false },

		applyType: { value: {}, valid: true, error: '', required: false },
		giveaways: { value: {}, valid: true, error: '', required: false },
		rentalBicycle: { value: {}, valid: true, error: '', required: false },
	},
};
